.NFTPicked {
  width: 43vw;
}

.NFTPicked-Container {
  margin-bottom: 3em;
}

.NFTPicked-Container-Image {
  display: flex;
  justify-content: center;
}
.NFTPicked-Container-ImgContainer-Left {
  width: 300px;
  position: relative;
  flex: 1;
  margin-left: 1rem;
}
.NFTPicked-Container-Image-Left-Background {
  width: 300px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
}

.NFTPicked-Container-Image-Left-Body {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
}
.NFTPicked-Container-Image-Left-Face {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
}
.NFTPicked-Container-Image-Left-Fur {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.NFTPicked-Container-Image-Left-Head {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}
.NFTPicked-Container-Image-Left-Hand {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 60;
}

.NFTPicked-Container-ImgContainer-Right {
  position: relative;
  width: 300px;
  flex: 1;
  border-radius: 12px;
}
.NFTPicked-Container-Image-Right-Background {
  width: 300px;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
}

.NFTPicked-Container-Image-Right {
  width: 300px;
  border-radius: 0px 12px 12px 0px;
}
.NFTPicked-Container-Image-Right-Fur {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.NFTPicked-Container-Hand {
  background: rgb(20, 17, 7);
  border-radius: 24px;
  margin-top: 10px;
  border: 1px solid yellow;
  cursor: pointer;
}

.NFTPicked-Container-Hand-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  height: 40px;
  margin: 0px 20px 0px 0px;
}
.NFTPicked-Container-Hand-Header-Sub {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.NFTPicked-Container-Hand-Header-Sub > p {
  color: black;
  font-size: 12px;
  background-color: yellow;
  padding: 5px;
  border-radius: 5px;
}

.NFTPicked-Container-Hand-Options {
  display: block;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.NFTPicked-Container-Hand-Options-Small {
  display: none;
}

.NFTPicked-Container-Hand-Options-Box {
  border: 1px solid yellow;
  border-radius: 12px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: wheat;
  position: relative;
}
.NFTPicked-Container-Hand-Options-Box > h4 {
  margin: 5px;
  color: black;
  height: 35px;
}

.NFTPicked-Container-Hand-Options-Box-Image {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  z-index: 50;
}

.NFTPicked-Container-Hand-Options-Box-Image-Back {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: absolute;
  z-index: 40;
}

.NFTPicked-Container-Hand-Options-Box-Image-Front {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: absolute;
  z-index: 60;
}

@media only screen and (max-width: 1400px) {
  .NFTPicked-Container-ImgContainer-Left,
  .NFTPicked-Container-Image-Left-Background,
  .NFTPicked-Container-Image-Left-Body,
  .NFTPicked-Container-Image-Left-Face,
  .NFTPicked-Container-Image-Left-Fur,
  .NFTPicked-Container-Image-Left-Head,
  .NFTPicked-Container-Image-Left-Hand,
  .NFTPicked-Container-ImgContainer-Right,
  .NFTPicked-Container-Image-Right-Background,
  .NFTPicked-Container-Image-Right,
  .NFTPicked-Container-Image-Right-Fur {
    width: 250px;
  }
}

@media screen and (max-width: 1150px) {
  .NFTPicked {
    width: 60vw;
  }

  .NFTPicked-Container {
    margin-bottom: 3em;
  }

  .NFTPicked-Container-Image {
    display: flex;
    justify-content: center;
  }

  .NFTPicked-Container-Hand {
    background: rgb(20, 17, 7);
    border-radius: 24px;
    margin-top: 10px;
    border: 1px solid yellow;
    cursor: pointer;
  }

  .NFTPicked-Container-Hand-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    height: 40px;
    margin: 0px 15px 0px 20px;
  }

  .NFTPicked-Container-Hand-Header-Sub {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .NFTPicked-Container-Hand-Header-Sub > p {
    color: black;
    font-size: 11px;
    background-color: yellow;
    padding: 5px;
    border-radius: 5px;
  }

  .NFTPicked-Container-Hand-Options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    cursor: pointer;
  }
  .NFTPicked-Container-Hand-Options-Small {
    display: none;
  }

  .NFTPicked-Container-Hand-Options-Box {
    border: 1px solid yellow;
    border-radius: 12px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: wheat;
    position: relative;
    width: 100px;
    height: 140px;
  }
  .NFTPicked-Container-Hand-Options-Box > h4 {
    color: black;
    font-size: 12px;
    text-align: center;
    height: fit-content;
  }

  .NFTPicked-Container-Hand-Options-Box-Image {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    z-index: 50;
  }

  .NFTPicked-Container-Hand-Options-Box-Image-Back {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    position: absolute;
    z-index: 40;
  }

  .NFTPicked-Container-Hand-Options-Box-Image-Front {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    position: absolute;
    z-index: 60;
  }

  /*.RightPanel {*/
  /*  padding-bottom: 10rem;*/
  /*}*/

  .RightPanel-Container-NFTS-Pick {
    min-height: fit-content;
  }

  .NFTPicked-Container-Image {
    margin-left: 7rem;
  }
}

/*@media only screen and (max-height: 900px) {*/
/*  .RightPanel {*/
/*    padding-bottom: 25rem;*/
/*  }*/
/*}*/

/*@media only screen and (max-height: 800px) {*/
/*  .RightPanel {*/
/*    padding-bottom: 20rem;*/
/*  }*/
/*}*/

/*@media only screen and (max-height: 725px) {*/
/*  .RightPanel {*/
/*    padding-bottom: 30rem;*/
/*  }*/
/*}*/

@media only screen and (max-width: 1500px) {
  .NFTPicked-Container-Image {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1450px) {
  .NFTPicked-Container-Image {
    margin-left: -1rem;
  }
}

@media only screen and (max-width: 1400px) {
  .NFTPicked-Container-Image {
    margin-left: 0.9rem;
  }
}

@media only screen and (max-width: 1350px) {
  .NFTPicked-Container-Image {
    margin-left: 0.5rem;
  }
}

@media only screen and (max-width: 1300px) {
  .NFTPicked-Container-Image {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1250px) {
  .NFTPicked-Container-Image {
    margin-left: -0.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .NFTPicked-Container-ImgContainer-Left,
  .NFTPicked-Container-Image-Left-Background,
  .NFTPicked-Container-Image-Left-Body,
  .NFTPicked-Container-Image-Left-Face,
  .NFTPicked-Container-Image-Left-Fur,
  .NFTPicked-Container-Image-Left-Head,
  .NFTPicked-Container-Image-Left-Hand,
  .NFTPicked-Container-ImgContainer-Right,
  .NFTPicked-Container-Image-Right-Background,
  .NFTPicked-Container-Image-Right,
  .NFTPicked-Container-Image-Right-Fur {
    width: 170px;
  }

  .NFTPicked-Container-Image {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 1150px) {
  .NFTPicked-Container-ImgContainer-Left,
  .NFTPicked-Container-Image-Left-Background,
  .NFTPicked-Container-Image-Left-Body,
  .NFTPicked-Container-Image-Left-Face,
  .NFTPicked-Container-Image-Left-Fur,
  .NFTPicked-Container-Image-Left-Head,
  .NFTPicked-Container-Image-Left-Hand,
  .NFTPicked-Container-ImgContainer-Right,
  .NFTPicked-Container-Image-Right-Background,
  .NFTPicked-Container-Image-Right,
  .NFTPicked-Container-Image-Right-Fur {
    width: 200px;
  }

  .NFTPicked-Container-Image {
    margin-left: 4rem;
  }
}

@media only screen and (max-width: 1050px) {
  .NFTPicked-Container-Image {
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 1000px) {
  .NFTPicked-Container-Image {
    margin-left: 2.5rem;
  }
}

@media only screen and (max-width: 950px) {
  .NFTPicked-Container-Image {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 900px) {
  .NFTPicked-Container-Image {
    margin-left: 1.5rem;
  }
}

@media only screen and (max-width: 850px) {
  .NFTPicked-Container-Image {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .NFTPicked-Container-Image {
    margin-left: 3rem;
  }

  .NFTPicked {
    width: 80vw;
  }
}

@media only screen and (max-width: 750px) {
  .NFTPicked-Container-Image {
    margin-left: 4rem;
  }

  .NFTPicked-Container-ImgContainer-Left,
  .NFTPicked-Container-Image-Left-Background,
  .NFTPicked-Container-Image-Left-Body,
  .NFTPicked-Container-Image-Left-Face,
  .NFTPicked-Container-Image-Left-Fur,
  .NFTPicked-Container-Image-Left-Head,
  .NFTPicked-Container-Image-Left-Hand,
  .NFTPicked-Container-ImgContainer-Right,
  .NFTPicked-Container-Image-Right-Background,
  .NFTPicked-Container-Image-Right,
  .NFTPicked-Container-Image-Right-Fur {
    width: 175px;
  }
}

@media only screen and (max-width: 700px) {
  .NFTPicked-Container-Image {
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 650px) {
  .NFTPicked-Container-Image {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .NFTPicked-Container-Image {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 550px) {
  .NFTPicked-Container-Image {
    margin-left: 0.7rem;
  }
}

@media only screen and (max-width: 525px) {
  .NFTPicked-Container-Image {
    margin-left: 0.1rem;
  }
}

@media only screen and (max-width: 500px) {
  .NFTPicked-Container-Image {
    margin-left: 1.5rem;
  }
  .NFTPicked-Container-ImgContainer-Left,
  .NFTPicked-Container-Image-Left-Background,
  .NFTPicked-Container-Image-Left-Body,
  .NFTPicked-Container-Image-Left-Face,
  .NFTPicked-Container-Image-Left-Fur,
  .NFTPicked-Container-Image-Left-Head,
  .NFTPicked-Container-Image-Left-Hand,
  .NFTPicked-Container-ImgContainer-Right,
  .NFTPicked-Container-Image-Right-Background,
  .NFTPicked-Container-Image-Right,
  .NFTPicked-Container-Image-Right-Fur {
    width: 125px;
  }
}

@media only screen and (max-width: 450px) {
  .NFTPicked-Container-Image {
    margin-left: 0.8rem;
  }
}

@media only screen and (max-width: 425px) {
  .NFTPicked-Container-Image {
    margin-left: 0.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .NFTPicked-Container-Image {
    margin-left: 0.2rem;
  }
}