#Retreat {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 4, 4);
  overflow: hidden;
}
.Retreat-Container > video {
  height: 100vh;
  width: 100vw;
}

@media only screen and (min-width: 1150px) {
  .Gif-Container {
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .Retreat-Container > video {
    display: none !important;
  }
  .Gif-Container > img {
    height: 40vh;
    width: 40vw;
    object-fit: cover;
    overflow: hidden;
  }
}

@media only screen and (max-width: 900px) {
  .Gif-Container > img {
    height: 50vh;
    width: 50vw;
  }
}

@media only screen and (max-width: 800px) {
  .Gif-Container > img {
    height: 60vh;
    width: 60vw;
  }
}