#Dashboard {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.RetreatLogo {
  position: absolute;
  left: 50px;
  top: 50px;
}
#Video-Background {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}
.RightPanel {
  background-color: black;
  /*height: 100vh;*/
  width: 50vw;
  position: absolute;
  right: 0;
  z-index: 10;
  /*height: 120vh;*/
}
.RightPanel-Container {
  margin: 15px;
  height: 90vh;
  color: white;
  display: flex;
  flex-direction: column;
}

.RightPanel-Container-Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.RightPanel-Container-Header {
  text-align: right;
  color: rgba(255, 255, 255, 0.25);
  margin: 10px 0px 10px 0px;
}
.RightPanel-Container-Intro {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
  align-items: center;
}
.RightPanel-Container-Intro-Imgs {
  display: flex;
  gap: 10px;
}
.RightPanel-Container-Intro-Imgs > img {
  cursor: pointer;
}
.RightPanel-Container-Line {
  border-bottom: 1px solid white;
  margin: 10px 0px 10px 0px;
}
.RightPanel-Container-Navbar {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
  align-items: center;
}
.RightPanel-Container-Navbar-SelectedNFTS-Container {
  display: flex;
  column-gap: 10px;
}
.RightPanel-Container-Navbar-SelectedNFTS {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
.RightPanel-Container-Navbar-Right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inputSearch {
  background: rgba(255, 255, 255, 0.1);
  padding: 3px;
  padding-right: 5px;
  margin: 5px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputSearch > input {
  border-radius: 5px;
  border: none;
  padding: 3px;
  margin: 5px;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  width: 12rem;
}

.inputSearch > img {
  height: 12px;
  margin-top: 10px;
}

.inputSearch > input::placeholder {
  text-align: center;
}

.RightPanel-Container-NFTS {
  gap: 30px;

  height: 60vh;
  display: grid;
  grid-template-columns: auto auto auto;
  flex-direction: column;
  overflow: scroll;
}
.RightPanel-Container-NFTS {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.RightPanel-Container-NFTS::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15rem;
}

.RightPanel-Container-Footer-Purchase {
  display: flex;
  flex-direction: column;
}

.continueBtn {
  cursor: pointer;
  position: relative;
  z-index: 100;
  width: 220px;
  margin-bottom: 0.7rem;
}
.unContinueBtn {
  cursor: not-allowed;
  width: 220px;
  margin-bottom: 0.7rem;

  /*scale: 0.8;*/
}



.RightPanel-Container-Footer-marketplaces {
  display: flex;
  gap: 10px;
  cursor: pointer;
  margin-top: 0.5rem;
}

.ErrorInfo {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
}

.ErrorInfo > h4 > span {
  color: yellow;
}

/* TESTT */

.buttonHolder {
  position: relative;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.electric {
  /* padding: 20px 50px; */
  /* border-radius: 100px; */
  cursor: pointer;
  /* background-color: rgba(106, 123, 252, 0.13); */
  /* border: 2px solid rgb(125, 140, 255); */
  /* color: white; */
  position: relative;
  filter: blur(0px);
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#electric2 {
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(1px);
  /* background-color: rgba(106, 123, 252, 0.414); */
  /* border: 2px solid rgb(125, 140, 255); */
  /* color: rgb(182, 191, 255); */
  /* display: none; */
  z-index: -1;
}

.electric > svg {
  position: absolute;
  z-index: -1;
}
.yellow {
  width: 110%;
  offset-path: 20%;
  stroke-dasharray: 300;
  stroke-dashoffset: 1000;
  animation: dash 1s linear;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

.blue {
  width: 110%;
  offset-path: 20%;
  stroke-dasharray: 400;
  stroke-dashoffset: 1000;
  animation: dash 1.2s linear;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

#y1 {
  top: -115%;
  left: -5%;
  filter: blur(0.5px);
}

#y2 {
  width: 120%;
  left: -10%;
  top: -155%;
  filter: brightness(1);
}

#b1 {
  left: -5%;
  top: -125%;
  /* filter: blur(.5px) */
}

#b2 {
  left: -10%;
  top: -165%;
  width: 120%;
  /* filter: blur(.5px) */
}

@keyframes dot1 {
  from {
    bottom: 0%;
    left: 10%;
    opacity: 1;
  }
  to {
    bottom: -30%;
    left: 0%;
    opacity: 0;
  }
}
@keyframes dot2 {
  from {
    top: 0%;
    left: 30%;
    opacity: 1;
  }
  to {
    top: -30%;
    left: 40%;
    opacity: 0;
  }
}

@keyframes dot3 {
  from {
    top: 0%;
    left: 80%;
    opacity: 1;
  }
  to {
    top: -30%;
    left: 80%;
    opacity: 0;
  }
}
@keyframes dot4 {
  from {
    bottom: 20%;
    left: 100%;
    opacity: 1;
  }
  to {
    bottom: 30%;
    left: 120%;
    opacity: 0;
  }
}

.part {
  content: " ";
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: rgb(183, 226, 255);
  z-index: 2;
  position: absolute;
  filter: blur(1px);
  display: none;
}

#part1 {
  animation: dot1;
  transform-origin: center;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}
#part2 {
  animation: dot2;
  transform-origin: center;
  animation-duration: 1.6s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}
#part3 {
  animation: dot3;
  transform-origin: center;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

#part4 {
  animation: dot4;
  transform-origin: center;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

.buttonHolder {
  width: max-content;
}

/*.buttonHolderOn {*/
/*  margin: 1rem 1rem 4rem 0;*/
/*}*/

.hide {
  opacity: 0;
}
.buttonHolderOn:hover .hide {
  opacity: 1;
}

.buttonHolderOn:hover .part {
  display: flex;
}

.Mobile-Continue-Btn {
  display: none;
}

.Fiendz-Found-Container {
  display: flex;
  flex-direction: row;
}

.Fiendz-Found-Count {
  width: 2rem;
}

@media only screen and (max-width: 1350px) {
  .unContinueBtn {
    scale: 0.8;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .continueBtn {
    scale: 0.8;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 1150px) {
  #Dashboard {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .RetreatLogo {
    position: absolute;
    left: 50px;
    top: 50px;
  }
  #Video-Background {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    display: none;
  }
  video.Video-Background {
    display: none;
  }
  .RightPanel {
    background-color: black;
    /*height: 100vh;*/
    width: 100vw;
    position: absolute;
    right: 0;
    z-index: 10;
    min-height: max-content;
  }
  .RightPanel-Container {
    margin: 15px;
    height: 100vh;
    color: white;

    display: flex;
    flex-direction: column;
  }
  .RightPanel-Container-Header {
    text-align: right;

    color: rgba(255, 255, 255, 0.25);
    margin: 10px 0px 10px 0px;
  }
  .RightPanel-Container-Intro {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 10px 0px;
    align-items: center;
  }
  .RightPanel-Container-Intro-Imgs {
    display: flex;
    gap: 10px;
  }
  .RightPanel-Container-Intro-Imgs > img {
    cursor: pointer;
  }
  .RightPanel-Container-Line {
    border-bottom: 1px solid white;
    margin: 10px 0px 10px 0px;
  }
  .RightPanel-Container-Navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
    align-items: center;
    font-size: 12px;
  }
  .RightPanel-Container-Navbar-SelectedNFTS-Container {
    display: flex;
    column-gap: 5px;
  }
  .RightPanel-Container-Navbar-SelectedNFTS {
    width: 30px;
    border-radius: 5px;
  }
  .RightPanel-Container-Navbar-Right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .inputSearch {
    background: rgba(255, 255, 255, 0.1);
    padding: 1px;
    padding-right: 5px;
    margin: 1px;
    border-radius: 12px;
  }
  .inputSearch > input {
    border-radius: 5px;
    border: none;
    padding: 1px;
    margin: 1px;
    color: white;
    background: rgba(255, 255, 255, 0.1);
    width: 12rem;
  }

  .inputSearch > img {
    height: 15px;
    margin-top: 3px;
    padding: 0 2px;
  }

  .inputSearch > input::placeholder {
    text-align: center;
  }

  .RightPanel-Container-NFTS {
    gap: 30px;

    height: 60vh;
    display: grid;
    grid-template-columns: auto auto auto;
    flex-direction: column;
    overflow: scroll;
  }
  .RightPanel-Container-NFTS {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }

  .RightPanel-Container-NFTS::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .RightPanel-Container-Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem 0 0.5rem;
    padding-top: 5px;
    height: 3rem;
  }

  .continueBtn {
    cursor: pointer;
    position: relative;
    z-index: 100;
    width: 120px;
    margin: -0.5rem auto 0 -0.5rem;
    scale: 1;
  }
  .unContinueBtn {
    margin-left: -0.5rem;
    cursor: not-allowed;
    width: 120px;
    scale: 1;
  }

  .RightPanel-Container-Footer-marketplaces {
    display: flex;
    gap: 10px;
    margin-left: -5px;
    cursor: pointer;
  }

  .ErrorInfo {
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
  }

  .ErrorInfo > h4 > span {
    color: yellow;
  }

  /* TESTT */

  .buttonHolder {
    position: relative;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

  .electric {
    /* padding: 20px 50px; */
    /* border-radius: 100px; */
    cursor: pointer;
    /* background-color: rgba(106, 123, 252, 0.13); */
    /* border: 2px solid rgb(125, 140, 255); */
    /* color: white; */
    position: relative;
    filter: blur(0px);
    z-index: 5;
  }

  #electric2 {
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(1px);
    /* background-color: rgba(106, 123, 252, 0.414); */
    /* border: 2px solid rgb(125, 140, 255); */
    /* color: rgb(182, 191, 255); */
    /* display: none; */
    z-index: -1;
  }

  .electric > svg {
    position: absolute;
    z-index: -1;
  }
  .yellow {
    width: 110%;
    offset-path: 20%;
    stroke-dasharray: 300;
    stroke-dashoffset: 1000;
    animation: dash 1s linear;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }

  .blue {
    width: 110%;
    offset-path: 20%;
    stroke-dasharray: 400;
    stroke-dashoffset: 1000;
    animation: dash 1.2s linear;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }

  #y1 {
    top: -115%;
    left: -5%;
    filter: blur(0.5px);
  }

  #y2 {
    width: 120%;
    left: -10%;
    top: -155%;
    filter: brightness(1);
  }

  #b1 {
    left: -5%;
    top: -125%;
    /* filter: blur(.5px) */
  }

  #b2 {
    left: -10%;
    top: -165%;
    width: 120%;
    /* filter: blur(.5px) */
  }

  @keyframes dot1 {
    from {
      bottom: 0%;
      left: 10%;
      opacity: 1;
    }
    to {
      bottom: -30%;
      left: 0%;
      opacity: 0;
    }
  }
  @keyframes dot2 {
    from {
      top: 0%;
      left: 30%;
      opacity: 1;
    }
    to {
      top: -30%;
      left: 40%;
      opacity: 0;
    }
  }

  @keyframes dot3 {
    from {
      top: 0%;
      left: 80%;
      opacity: 1;
    }
    to {
      top: -30%;
      left: 80%;
      opacity: 0;
    }
  }
  @keyframes dot4 {
    from {
      bottom: 20%;
      left: 100%;
      opacity: 1;
    }
    to {
      bottom: 30%;
      left: 120%;
      opacity: 0;
    }
  }

  .part {
    content: " ";
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: rgb(183, 226, 255);
    z-index: 2;
    position: absolute;
    filter: blur(1px);
    display: none;
  }

  #part1 {
    animation: dot1;
    transform-origin: center;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-iteration-count: infinite;
  }
  #part2 {
    animation: dot2;
    transform-origin: center;
    animation-duration: 1.6s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-iteration-count: infinite;
  }
  #part3 {
    animation: dot3;
    transform-origin: center;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-iteration-count: infinite;
  }

  #part4 {
    animation: dot4;
    transform-origin: center;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-iteration-count: infinite;
  }

  .buttonHolder {
    width: max-content;
  }

  .hide {
    opacity: 0;
  }
  .buttonHolderOn:hover .hide {
    opacity: 1;
  }

  .buttonHolderOn:hover .part {
    display: flex;
  }
}

@media only screen and (max-width: 1150px) {
  #Dashboard {
    /*height: 105vh;*/
  }

  .RightPanel {
    /*height: 105vh;*/
  }

  .RightPanel-Container-Navbar {
    display: flex;
    flex-direction: column-reverse;
  }

  .RightPanel-Container-Navbar-Right {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .RightPanel-Container-NFTS {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*flex-wrap: nowrap;*/
    justify-content: center;
    gap: 0;
    overflow: scroll;
    padding-top: 1rem;
  }

  .RightPanel-Container-Navbar-SelectedNFTS-Container {
    height: 30px;
    margin-bottom: 0.5rem;
  }

  .ErrorInfo {
    margin-top: 2rem;
  }
  .ErrorInfo > h4 {
    text-align: center;
  }
  .Dashboard-Logo-Icon {
    width: 2rem;
    margin: 0 0.5rem;
  }

  .Mobile-Continue-Btn {
    display: flex;
    margin: 0rem 0 0.5rem 0;
  }

  .Mobile-Continue-Btn > img {
    transform: scale(1.4);
  }

  #electric {
    display: none;
  }

  .Fiendz-Found-Count {
    display: flex;
    justify-content: center;
    width: 1.8rem;
  }


  .Mobile-Footer-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div.RightPanel-Container-Intro > div > h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1150px) {
  .Mobile-Footer-Container {
    display: none;
  }
}

@media only screen and (max-width: 700px) {

  .ErrorInfo {
    margin-top: 2rem;
  }
  .ErrorInfo > h4 {
    text-align: center;
  }
  .Dashboard-Logo-Icon {
    width: 2rem;
    margin: 0 0.5rem;
  }

  .RightPanel-Container-Footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
    padding-bottom: 0;
    padding-top: 18px;
  }
  .RightPanel-Container-Footer-marketplaces {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Fiendz-Found {
    font-size: 14px;
  }

  .RightPanel-Container-Footer-Purchase {
    display: none;
  }

  .Mobile-Footer-Container {
    /*margin-bottom: -1rem;*/
  }

  /*.buttonHolderOn {*/
  /*  display: none;*/
  /*}*/
}

@media only screen and (max-width: 600px) {
  .RightPanel-Container-Header > h4 {
    font-size: 14px;
  }
  .RightPanel-Container-Intro > h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 550px) {
  .RightPanel-Container-Intro > h3 {
      font-size: 16px;
  }
}
@media only screen and (max-width: 481px) {
  .RightPanel-Container-Intro > h3 {
    font-size: 14px;
  }
}