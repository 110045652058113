.NFTCard {
  height: 180px;
  width: 180px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 8px 8px 15px 8px;
}

.NFTCard-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  gap: 10px;
  position: relative;
}

.NFT-Image {
  height: 150px;
  width: 150px;
  border-radius: 12px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.NFT-Image:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.Nft-Id {
  align-self: flex-start;
}

.selectedImage {
  border: 1px solid yellow;
  scale: 0.9;
  border-radius: 24px;
  color: yellow;
}

.NFTCard:hover {
  border: 1px solid yellow;
  border-radius: 24px;
  color: yellow;
}

.addSign {
  display: none;
}

.addedSign {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
}

.NFTCard:hover .addSign {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
}

@media screen and (max-width: 1150px) {
  .NFTCard {
    height: 140px;
    width: 140px;
    cursor: pointer;
    padding: unset;
  }
  .NFTCard-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    gap: 10px;
    position: relative;
  }
  .NFT-Image {
    height: 120px;
    width: 120px;
    border-radius: 12px;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .NFT-Image:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  .Nft-Id {
    align-self: flex-start;
    font-size: 12px;
  }

  .selectedImage {
    border: 1px solid yellow;
    scale: 0.9;
    padding: 10px;
    border-radius: 24px;
    color: yellow;
  }

  .NFTCard:hover {
    border: 1px solid yellow;
    border-radius: 24px;
    color: yellow;
  }
  .addSign {
    display: none;
  }
  .addedSign, addSign {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .addedSign > img, .addSign > img {
    height: 20px;
    width: 20px;
  }
  .NFTCard:hover .addSign {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

@media only screen and (max-width: 1150px) {
  .NFTCard-Container {
    margin: 1rem;
  }

  .NFTCard {
    height: fit-content;
    width: fit-content;
    margin: 0 1rem;
  }

  .NFT-Image {
    height: 10rem;
    width: 10rem;
    padding: 0;
  }

  .Nft-Id {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .selectedImage {
    border: 1px solid yellow;
    scale: 0.9;
    padding: 0.8rem 0 0.2rem 0;
    border-radius: 24px;
    color: yellow;
  }

  .Nft-Id {
    font-size: 14px;
  }
}

@media only screen and (max-width: 850px) {
  .NFT-Image {
    height: 9rem;
    width: 9rem;
  }

  .Nft-Id {
    font-size: 12px;
  }
}

@media only screen and (max-width: 700px) {
  .NFT-Image {
    height: 8rem;
    width: 8rem;
  }
}

@media only screen and (max-width: 500px) {
  .NFT-Image {
    height: 6rem;
    width: 6rem;
  }
}