#SignIn {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/background.png");
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SignIn-Container {
  background-color: black;
  height: 25vh;
  width: 25vw;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
.SignIn-Container > h1 {
  color: white;
  margin-top: 40px;
}

.SignIn-Container > button {
  background-color: yellow;
  border: none;
  padding: 10px 30px 10px 30px;
  cursor: pointer;
  border-radius: 5px;
  color: black;
}

@media screen and (max-width: 500px) {
  #SignIn {
    height: 100vh;
    width: 100vw;
    background-image: url("../../assets/background.png");
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SignIn-Container {
    background-color: black;
    height: 25vh;
    width: 45vw;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
  }
  .SignIn-Container > h1 {
    color: white;
    margin-top: 40px;
  }

  .SignIn-Container > button {
    background-color: yellow;
    border: none;
    padding: 10px 30px 10px 30px;
    cursor: pointer;
    border-radius: 5px;
  }
}


@media only screen and (max-width: 700px) {
  .SignIn-Container {
    width: 18rem;
    margin: 0 1rem;
  }
}