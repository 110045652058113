.FNDZ-Logo-Container {
    cursor: pointer;
    margin: 10px 0 0 0;
}

.Logo-Link-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    height: 10px;
    transition: all 200ms ease-in-out;
    opacity: 0.6;
}


.Developed-By-Text {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 8px;
    font-weight: 500;
    transition: all 200ms ease-in-out;
    color: white;
}

.FNDZ-Squares-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 16px;
    margin: 0 3px 0 2px;
}

.FNDZ-Squares {
    margin: 0 1px 0 5px;
    height: 12px;
    width: 12px;
}

.Logo-Name-Text {
    font-family: "Red Hat Display", sans-serif;
    font-size: 8px;
    font-weight: 800;
    transition: all 200ms ease-in-out;
    color: white;
}

.Logo-Link-Wrapper:hover {
    transition: all 300ms ease-in-out;
    opacity: 1;
}