@import url("https://fonts.googleapis.com/css2?family=Varela&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unbounded&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aboreto&display=swap");

body {
  margin: 0;
  /* font-family: "Varela", sans-serif; */
  /* font-family: "Staatliches", cursive; */
  font-family: "Unbounded", cursive;
  /* font-family: "Aboreto", cursive; */
  background-color: black;
}
