#IntroPage {
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.IntroPage-Container {
  height: 20vh;
  width: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.IntroPage-Logo {
  cursor: pointer;
  height: 80px;
  /*-webkit-transform: scale(1.3);*/
  /*transform: scale(1.3);*/
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.IntroPage-Logo:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.progress-div {
  background-color: black;
  border-radius: 0.5rem;
  width: 200px;
  margin-top: 20px;
}

.progress {
  background-color: yellow;
  height: 10px;
  border-radius: 1rem;
  transition: 1s ease;
}

.IntroPage-H4 {
  margin-top: 1rem;
  text-align: center;
  min-width: 16rem;
  width: 100%;
  color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: normal;
}

@media only screen and (max-width: 1150px) {
  .IntroPage-H4 {
    margin: 1rem 0 2rem 0;
  }
}