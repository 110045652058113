.Footer, .Intro-Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 10000;
  border-top: 1px solid gray;
}

.Intro-Footer {
  padding: 1rem 0;
  display: none;
}

.Intro-Footer > .Mobile-Footer-Container > .Footer-Logo-Right {
  margin-left: 1rem;
}

.Footer-Container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem;
}

.Footer-Logo-Right {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-right: 17rem;
  margin-left: 1rem;
}

.Footer-Logo-Right > img {
  width: 150px;
}

.Footer-Info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Footer-Info > p {
  text-align: center;
  font-size: 12px;
}

.Footer-Info > p > span {
  color: yellow;
  cursor: pointer;
}

.Socials-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.Socials-container > img {
  height: 30px;
  width: 30px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.social {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.social:hover,
.Footer-Logo-Right:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  color: yellow;
}
.DevelopedByFNDZ {
  font-size: 12px;
}

.Footer-Info > p > a {
  text-decoration: none;
  color: yellow;
}

.volumeOption {
  right: 10px;
  bottom: 5px;
}

.volumeOption > p {
  display: flex;
  flex-direction: row;
}

.volumeOption > p > span {
  white-space: pre;
  margin-right: 5px;
}

.volumeAmount {
  display: block;
}

.volumeAmountNumber {
  display: block;
  width: 2rem;
}

.volumeOption-mobile {
  display: none;
}

.volumeOption > input {
  margin-right: 10px;
  background-color: yellow;
  color: yellow;
}

.volumeSlider::-webkit-slider-thumb {
  background-color: yellow;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-appearance: none;
  appearance: none;
}

.volumeSlider::-webkit-slider-runnable-track {
  border: 1px solid yellow;
  background-color: black;
  border-radius: 12px;
  padding: 5px;
}

.Footer-Container {
  display: flex;
  justify-content: space-between;
}

.Social-Volume {
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem;
}

.Footer-Socials {
  margin: 0 3rem;
}

.Footer-OS-Logo:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 1280px) {
  .Footer-Logo-Right {
    margin-right: 16rem;
  }
}

@media only screen and (max-width: 1260px) {
  .Footer-Logo-Right {
    margin-right: 14rem;
  }
}

@media only screen and (max-width: 1232px) {
  .Footer-Logo-Right {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1150px) {
  .Footer-Logo-Right {
    margin-right: 0;
  }

  .Footer-Logo-Right > img {
    margin-left: 0.6rem;
  }
}

@media only screen and (max-width: 1150px) {
  .Footer {
    display: none;
  }

  .Intro-Footer {
    display: unset;
    background-color: black;
  }

  .Footer-Container {
    display: flex;
    flex-direction: column;
  }

  .Footer-Logo-Right, .Footer-Info, .Social-Volume {
    margin: 0.3rem 0.2rem;
  }

  .Footer-Logo-Right:hover {
    transform: scale(1.05);
  }

  .Footer-Logo-Right {
    margin-right: 2rem;
  }

  .Mobile-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .Social-Volume {
    display: flex;
    flex-direction: column;
  }

  .Socials-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .social, img.Footer-OS-Logo {
    height: 1.5rem;
    width: 1.5rem;
  }

  .social:hover, .Footer-OS-Logo:hover {
    transform: scale(0.9);
  }

  .volumeOption {
    display: none;
  }

  .volumeOption-mobile {
    display: block;
  }

  .volumeAmount {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1225px) {
  .Footer-Info {
    margin-left: 7rem;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .Footer-Info {
    margin-left: 5rem;
  }
}

@media only screen and (max-width: 1150px) {
  div.Footer-Logo-Right {
    display: none;
  }
  div.Mobile-Footer-Container {
    margin-top: -8px;
  }
}

@media only screen and (max-width: 900px) {
  .Footer-Info {
    display: none;
  }

  .volumeOption-mobile {
    display: none;
  }
}