@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  font-family: "Unbounded", cursive;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

@media only screen and (max-width: 1150px) {
  html, body {
    background-color: black;
  }
}