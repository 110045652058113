.RightPanel-Container-Intro-Button {
  background: none;
  color: white;
  border: none;
  padding: 5px;
  position: absolute;
  top: 20px;
  left: 40px;

  border: 1px solid yellow;
  border-radius: 12px;
  cursor: pointer;
}
.RightPanel-Container-Info-Button {
  background: none;
  color: white;
  border: none;
  padding: 5px;
  position: absolute;
  top: 20px;
  left: 130px;

  border: 1px solid yellow;
  border-radius: 12px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  border: 1px solid rgba(255, 255, 0, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: rgba(255, 255, 0, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px yellow;
  border: 1px solid rgba(255, 255, 0, 1);
}
input:focus + .slider::before {
  background-color: rgba(255, 255, 0, 1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.AreYouSureContainer {
  height: 350px;
  width: 400px;
  display: none;
  background-color: #090406;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 12px;
  z-index: 1000;
}
.AreYouSureContainer-Container {
  margin: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  text-transform: uppercase;
  text-align: center;
}
.AreYouSureContainer-Container-BTNS {
  display: flex;
  column-gap: 10px;
}
.AreYouSureContainer-Container-BTNS > button {
  border: none;
  background-color: yellow;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
}

.display {
  display: block;
}
.blur {
  filter: blur(5px);
}
.displayNone {
  display: none;
}

.FinalPage {
  display: none;
  height: 100vh;
  width: 100vw;
  color: white;
  position: absolute;
  z-index: 100;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.FinalPage-Truth-Awaits {
  text-align: center;
  font-size: 20px;
}
.displayFlex {
  display: flex;
}
.FinalPage-Intro {
  text-align: center;
}
.FinalPage-Intro > h1 {
  font-size: 54px;
  margin-bottom: 20px;
  margin-top: -3rem;
}
.FinalPage-Intro > h4 {
  font-size: 28px;
  margin-bottom: 20px;
}

.FinalPage-Buttons {
  display: flex;
  column-gap: 30px;
  margin-top: 30px;
}

.FinalPage-Buttons > button {
  border: none;
  background-color: yellow;
  padding: 15px 45px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.FinalPage-Buttons > button:hover {
  scale: 1.1;
}

.FinalPage-Images-NFT {
  position: relative;
  width: 300px;
  border-radius: 12px;
}

.FinalPage-Images {
  height: 300px;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
}
.FinalPage-Images-PlusSign {
  align-self: center;
}
.FinalPage-Footer {
  font-size: 12px;
  position: absolute;
  left: 30px;
  bottom: 20vh;
}

.FinalPage-Images-NFT-Background {
  width: 300px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
}
.FinalPage-WalletAddress {
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.25);
}

.RightPanel-Container-Pick {
  margin: 20px 40px 40px 40px;
  height: 90vh;
  color: white;

  display: flex;
  flex-direction: column;
}

.RightPanel-Container-NFTS-Pick {
  gap: 30px;
  /*height: 66vh;*/
  display: grid;
  grid-template-columns: auto auto auto;
  flex-direction: column;
  overflow: scroll;
}
.RightPanel-Container-NFTS-Pick {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  /*overflow-y: scroll;*/
}

.RightPanel-Container-NFTS-Pick::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.finalShroom {
  width: 300px;
  border-radius: 12px;
}

.FiendView {
  margin-top: 30px;
}

.Fiend-To-Burn-Toggle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Fiend-To-Burn-Toggle > h4 {
  width: 12rem;
}

.Mobile-Continue-Btn-Small {
  display: none;
}

@media screen and (max-width: 1150px) {
  .Fiend-To-Burn-Toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Fiend-To-Burn-Toggle > h4 {
    margin-bottom: 10px;
  }

  .RightPanel-Container-Intro-Button {
    background: none;
    color: white;
    border: none;
    padding: 5px;

    position: absolute;
    top: 40px;

    border: 1px solid yellow;
    border-radius: 12px;
    cursor: pointer;
  }
  .RightPanel-Container-Info-Button {
    background: none;
    color: white;
    border: none;
    padding: 5px;

    position: absolute;
    top: 40px;
    left: 150px;

    border: 1px solid yellow;
    border-radius: 12px;
    cursor: pointer;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    border: 1px solid rgba(255, 255, 0, 1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: rgba(255, 255, 0, 1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: black;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px yellow;
    border: 1px solid rgba(255, 255, 0, 1);
  }
  input:focus + .slider::before {
    background-color: rgba(255, 255, 0, 1);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .AreYouSureContainer {
    height: 300px;
    width: 450px;
    display: none;
    background-color: #090406;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    border-radius: 12px;
    z-index: 1000;
  }
  .AreYouSureContainer-Container {
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    text-transform: uppercase;
    text-align: center;
  }
  .AreYouSureContainer-Container > h1 {
    font-size: 24px;
  }
  .AreYouSureContainer-Container > div > p {
    font-size: 14px;
    margin: 0 auto 0.5rem auto;
  }
  .AreYouSureContainer-Container-BTNS {
    display: flex;
    column-gap: 10px;
  }
  .AreYouSureContainer-Container-BTNS > button {
    border: none;
    background-color: yellow;
    padding: 10px 0;
    width: 130px;
    border-radius: 4px;
    cursor: pointer;
    color: black;
  }

  .display {
    display: block;
  }
  .blur {
    filter: blur(5px);
  }
  .displayNone {
    display: none;
  }
  .displayFlex {
    display: flex;
  }
  .FinalPage-Intro {
    text-align: center;
  }
  .FinalPage-Intro > h1 {
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 0.5rem;
  }
  .FinalPage-Intro > h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .FinalPage-Buttons {
    display: flex;
    column-gap: 30px;
    margin-top: 30px;
  }

  .FinalPage-Buttons > button {
    border: none;
    background-color: yellow;
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: black;
  }

  .FinalPage-Buttons > button:hover {
    scale: 1.1;
  }

  .FinalPage-Images-NFT {
    position: relative;
    width: 150px;
    border-radius: 12px;
  }

  .FinalPage-Images {
    height: 150px;
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  .FinalPage-Images-PlusSign {
    align-self: center;
    margin-bottom: 18px;
  }
  .FinalPage-Images-PlusSign > img {
    width: 30px;
  }
  .FinalPage-Footer {
    font-size: 12px;
    position: absolute;
    left: 30px;
    bottom: 20vh;
  }

  .FinalPage-Images-NFT-Background {
    width: 150px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
  }
  .FinalPage-WalletAddress {
    position: absolute;
    top: 5px;
    right: 5px;
    color: rgba(255, 255, 255, 0.25);
    font-size: 12px;
  }

  .RightPanel-Container-Pick {
    margin: 40px;
    /*height: 90vh;*/
    color: white;

    display: flex;
    flex-direction: column;
  }

  .RightPanel-Container-NFTS-Pick {
    gap: 30px;
    /*height: 66vh;*/
    display: flex;
    margin: 1rem auto;
    grid-template-columns: auto auto auto;
    flex-direction: column;
    /*overflow: scroll;*/
  }
  .RightPanel-Container-NFTS-Pick {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    /*overflow-y: scroll;*/
  }

  .RightPanel-Container-NFTS-Pick::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  div.FinalPage-Images {
    margin-bottom: 0;
    gap: 15px;
  }

  .FinalPageImage.FinalPage-Images-NFT,
  .FinalPageImage.FinalPage-Images-NFT-Background,
  .FinalPageImage.NFTPicked-Container-Image-Left-Hand,
  .FinalPageImage.NFTPicked-Container-Image-Left-Fur,
  .FinalPageImage.NFTPicked-Container-Image-Left-Body,
  .FinalPageImage.NFTPicked-Container-Image-Left-Face,
  .FinalPageImage.NFTPicked-Container-Image-Left-Head,
  .FinalPageImage.NFTPicked-Container-Image-Left-Hand,
  .finalShroom {
    width: 115px;
    margin-top: 5px;
    display: flex;
  }
  .finalShroom {
    margin-top: 10px;
    border-radius: 12px;
  }
  .FinalPage-Truth-Awaits {
    font-size: 16px;
    margin: 0 2.5rem;
  }
  .FiendView {
    margin-top: 30px;
    text-align: center;
  }
  .FiendView > p {
    margin: 5px 0;
  }
  .RightPanel-Container-Footer-Mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Mobile-Continue-Btn-Small {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  div.FinalPage-Buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  div.FinalPage-Buttons > button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1150px) {
  /*.RightPanel-Container-Navbar-SelectedNFTS-Container {*/
  /*  margin-left: -3.7rem;*/
  /*}*/

  div.RightPanel-Container-Footer-Mobile > .RightPanel-Container-Footer > .Fiend-To-Burn-Toggle > div {
    margin-left: -3.7rem;
  }

  .RightPanel-Container-Info-Button {
    left: 125px;
  }

  .Mobile-Continue-Btn-Small {
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 900px) {
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    border: 1px solid rgba(255, 255, 0, 1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  span.slider.round {
    height: 22px;
    width: 49px;
  }

  label.switch {
    margin-left: 6px;
  }

  .slider::before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: rgba(255, 255, 0, 1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

@media only screen and (max-width: 700px) {
  .RightPanel-Container-Footer-Mobile {
    margin-bottom: 1rem;
  }

  .Fiend-To-Burn-Toggle > h4 {
    font-size: 14px;
  }

  .RightPanel-Container-Navbar-SelectedNFTS-Container {
    margin-left: -0.2rem;
  }

  .Mobile-Continue-Btn-Small {
    margin-top: 0.5rem;
  }
  .AreYouSureContainer {
    top: 45%;
    width: 65vw;
  }
  .AreYouSureContainer-Container {
    row-gap: 25px;
  }
  .AreYouSureContainer-Container > div > p {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .AreYouSureContainer {
    width: 80vw;
  }
}

@media only screen and (max-width: 500px) {
  .RightPanel-Container-Footer {
    padding-top: 15px;
  }
  .Fiend-To-Burn-Toggle > h4 {
    font-size: 12px;
  }
  .AreYouSureContainer-Container {
    row-gap: 15px;
  }
  .AreYouSureContainer-Container-BTNS > button {
    width: 110px;
  }
}

@media only screen and (max-width: 400px) {
  .Mobile-Continue-Btn-Small {
    margin-top: 0.5rem;
    margin-left: -1rem;
  }
  .AreYouSureContainer {
    height: 325px;
  }
  .AreYouSureContainer-Container {
    row-gap: 10px;
  }
}